import React, { useState } from 'react';
import { Box, Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Login from '../../pages/Login';

const LoginOverlay = ({ isDisplayed, message }) => {
  const [isOpen, setIsOpen] = useState(isDisplayed);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = (e) => {
    // Close the overlay only if the user clicks outside the card
    if (e.target.id === 'login-overlay') {
      handleClose();
    }
  };

  if (!isOpen) return null;

  return (
    <Box
      id="login-overlay"
      onClick={handleOverlayClick}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Card
        sx={{
          position: 'relative',
          p: 3,
          minWidth: 300,
          maxWidth: 500,
          textAlign: 'center',
          margin: 3,
        }}
      >
        {/* Close Icon */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Login msg={message} />
      </Card>
    </Box>
  );
};

export default LoginOverlay;
