import React from 'react';
import { Card, Typography } from '@mui/material';
import FaceOffDataTable from './FaceOffDataTable'; // Ensure you have the FaceOffDataTable component

const UserLeaderboard = () => {
  return (
    <Card sx={{ mt: 2, p: 1 }}>
      <FaceOffDataTable
        endpoint={`${process.env.REACT_APP_SERVER_URL}/getProfileLeaderboard`}
        headCells={[
          {
            id: 'external_tiny_avatar_URL',
            label: 'Avatar',
            type: 'image',
            numeric: false,
          },
          { id: 'display_name', label: 'Username', type: 'link', numeric: false, properties: {page_name:'User/', link_data:"id"} },
          { id: 'seededRate', label: '% Games Seeded', type: 'text', numeric: true, hideMobile: true },
          { id: 'rankingCount', label: '# Games Compared', type: 'text', numeric: true, hideMobile: true },
          { id: 'score', label: 'Total Score', type: 'text', numeric: true },
        ]}
        defaultSortField="score"
        defaultSortOrder="desc"
      />
    </Card>
  );
};

export default UserLeaderboard;
