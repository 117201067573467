import React, { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import { useParams } from 'react-router-dom';
import TabbedPageLayout from '../components/TabbedPageLayout';
import CriterionCard from '../components/CriterionCard';
import TierList from '../components/TierList';
import AITester from '../components/AITester';
import SEO from '../components/SEO';
import axios from 'axios';
import { CircularProgress, Alert } from '@mui/material';
import FaceOffDataTable from '../components/FaceOffDataTable';
import ShareButtons from '../components/chips/ShareButtons';
import GenericChip from '../components/chips/GenericChip';
import { Star, Face } from '@mui/icons-material';

const LeaderboardPage = () => {
  const [criterionData, setCriterionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [leaderboardId, setLeaderboardId] = useState(null);
  const { user } = useUser();
  const { criterion_name, id } = useParams();

  useEffect(() => {
    const fetchLeaderboardDetails = async () => {
      try {
        let endpoint = '';
        if (id) {
          setLeaderboardId(id);
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboard/${id}`;
        } else if (criterion_name) {
          endpoint = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardByCriterionName/${criterion_name}`;
        }
        if (!endpoint) throw new Error('Invalid URL parameters.');

        const response = await axios.get(endpoint);
        setCriterionData(response.data);
        setLeaderboardId(response.data.corelation_id);
      } catch (err) {
        setError('Error fetching leaderboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardDetails();
  }, [criterion_name, id]);

  if (loading) {
    return (
      <>
        <SEO title="Loading Leaderboard..." description="Loading leaderboard data. Please wait..." />
        <CircularProgress />
      </>
    );
  }

  if (error) {
    return (
      <>
        <SEO title="Error - Leaderboard" description="An error occurred while loading the leaderboard data." />
        <Alert severity="error">{error}</Alert>
      </>
    );
  }

  const headCells = [
    { id: 'large_image_url', label: 'Image', type: 'image', numeric: false },
    { id: 'ranking', label: 'Ranking', type: 'text', numeric: true },
    { id: 'tier', label: 'Tier', type: 'tier', numeric: false },

    {
      id: 'name',
      label: 'Game',
      type: 'link',
      hideMobile: true,
      properties: { page_name: 'competitor/', link_data: 'corelation_id' },
    },
    { id: 'ranked_count', label: '# Ranked', type: 'number',hideMobile:true },
    { id: 'percentile', label: 'Percentile Score', type: 'number',hideMobile:true },
    {
      id: 'faceoff',
      label: '',
      type: 'faceoff',
      numeric: false,
      properties: { criterion: 'criterion', competitor: 'corelation_id' },
    },
    {
      id: 'share',
      label: '',
      type: 'share',
      numeric: false,
      properties: {
        primaryCompetitorId: 'corelation_id',
        primaryCompetitorName: 'name',
        primaryCompetitorImage: 'large_image_url',
        criterion: 'criterion',
      },
    },
  ];

  const customTopCard = (
    <CriterionCard
      title={criterionData.name}
      name={criterionData.criterion_name}
      description={criterionData.leaderboard_description}
      avatar_url={criterionData.large_image_url}
      criterionStyles={{
        emoji: criterionData.criterion_emoji || '',
        transition_1_color: criterionData.transition_color_1 || '#4a4a4a',
        transition_2_color: criterionData.transition_color_2 || '#141414',
        font_color: criterionData.font_color || '#FFFFFF',
      }}
      tooltipText="This is a tooltip for the leaderboard"
    />
  );

  const tabs = [
    {
      label: 'Tier List',
      title: 'Tier List',
      subtitle: 'Tier lists are a contemporary way to represent which is best. All users\' rankings are considered in this tier list.',
      content: (
        <>
            <ShareButtons title={criterionData.criterion_name} url={`${process.env.REACT_APP_SERVER_URL}/share-tier-list/${leaderboardId}`} displayName={"Share Tier List"} />
            {
              user && (
                <GenericChip label="See Your Tierlist" url={`/UserTierList/criterion_name/${criterionData.criterion_name}`} icon={<Face />} />
              )
            }
            <TierList criterion={criterionData.criterion_name} />
        </>
      ),
    },
    {
      label: 'Statistics',
      title: 'Statistics',
      subtitle: 'More detailed statistics about the tier list.',
      content: (
        <>
          <FaceOffDataTable
            endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?criterion=${criterionData.criterion_name}&seeded=1`}
            headCells={headCells}
            defaultSortOrder="asc"
            defaultSortField="ranking"
          />
        </>
      ),
    },
    user?.accountType === 'admin' && {
      label: 'AI Tester',
      title: 'AI Tester',
      content: (
        <>
          <AITester
            criterion={criterionData.criterion_name}
            competitorDescriptor="Video Game"
            criterionDescriptor="Category"
          />
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <SEO
        title={`Leaderboard - ${criterionData?.criterion_name || 'Leaderboard'}`}
        description={`Explore the leaderboard for the best ${criterionData?.criterion_name}.`}
        url={window.location.href}
      />
      <TabbedPageLayout customTopCard={customTopCard} tabs={tabs} />
    </>
  );
};

export default LeaderboardPage;
