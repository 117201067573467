import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './TierList.css'; // Keep the CSS file for custom styles
import FaceOffImageList from './FaceOffImageList';

const tiers = ['S', 'A', 'B', 'C', 'D', 'F'];

const TierList = ({ criterion, profile, leaderboardId }) => {
  return (
    <Container maxWidth="x2" className="tier-list">
      {tiers.map((tier, index) => {
        // Build the `dataSource` URL dynamically
        const queryParams = [
          criterion ? `criterion=${criterion}` : '',
          leaderboardId ? `leaderboard_id=${leaderboardId}` : '',
          `seeded=1`,
          profile ? `profile_id=${profile}` : null, // Include `profile_id` only if it's not null/undefined
          tier ? `tier=${tier}` : '',
        ]
          .filter(Boolean) // Remove any null/undefined values
          .join('&');

        return (
          <Box
            key={tier}
            sx={{
              backgroundColor: index % 2 !== 0 ? '#f5f5f5' : 'transparent', // Apply light gray background to odd-indexed boxes
              borderRadius: index % 2 !== 0 ? '8px' : '0px', // Rounded corners for odd-indexed boxes
            }}
          >
            <Box
              className={`tier tier-${tier}`}
              sx={{ display: 'flex', alignItems: 'center', m: 2 }}
            >
              <Typography
                className="tier-header"
                variant="h2"
                component="div"
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: '10px',
                  textAlign: 'center',
                  mr: 2,
                }}
              >
                {tier}
              </Typography>
              <Box
                className="tier-content"
                sx={{
                  display: 'flex',
                  overflowX: 'auto',
                  flexWrap: 'nowrap',
                }}
              >
                <FaceOffImageList
                  dataSource={`${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?${queryParams}`}
                  showSearch={false}
                  metricDescriptor="elo"
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Container>
  );
};

export default TierList;
