import React, { useState } from 'react';
import { CheckCircle, Cancel, Lock } from '@mui/icons-material';

const SwipeableCard = ({ id, children, onSwipe, disabled = false }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(null);
  const [swipeInProgress, setSwipeInProgress] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [fadedOut, setFadedOut] = useState(false);

  const handleStart = (clientX, clientY) => {
    if (swipeInProgress || disabled) return;
    setIsDragging(true);
    setStartPosition({ x: clientX, y: clientY });
  };

  const handleMove = (clientX, clientY) => {
    if (!isDragging || swipeInProgress || disabled) return;

    const deltaX = clientX - startPosition.x;
    setPosition({ x: deltaX, y: 0 });

    setSwipeDirection(deltaX > 0 ? 'right' : 'left');
  };

  const handleEnd = () => {
    if (!isDragging || swipeInProgress || disabled) return;

    setIsDragging(false);

    const threshold = 100;
    let finalDirection = null;

    if (Math.abs(position.x) > threshold) {
      finalDirection = position.x > 0 ? 'right' : 'left';
    }

    if (finalDirection) {
      setSwipeInProgress(true);

      setPosition({
        x: finalDirection === 'left' ? -window.innerWidth : window.innerWidth,
        y: 0,
      });

      setTimeout(() => {
        setFadedOut(true);
        onSwipe(finalDirection, id);
      }, 300);
    } else {
      setPosition({ x: 0, y: 0 });
      setSwipeDirection(null);
    }
  };

  return (
    !fadedOut && (
      <div
        onMouseDown={(e) => handleStart(e.clientX, e.clientY)}
        onMouseMove={(e) => handleMove(e.clientX, e.clientY)}
        onMouseUp={handleEnd}
        onMouseLeave={() => isDragging && handleEnd()}
        onTouchStart={(e) => handleStart(e.touches[0].clientX, e.touches[0].clientY)}
        onTouchMove={(e) => handleMove(e.touches[0].clientX, e.touches[0].clientY)}
        onTouchEnd={handleEnd}
        style={{
          width: 'auto',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: disabled ? 'not-allowed' : isDragging ? 'grabbing' : 'grab',
          transform: `translate(${position.x}px, 0)`,
          transition: swipeInProgress ? 'transform 0.3s ease, opacity 0.3s ease' : 'transform 0.3s ease',
          opacity: swipeInProgress ? 0 : 1,
          position: 'relative',
        }}
      >
        {disabled && (
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              borderRadius: '50%',
              padding: '5px',
            }}
          >
            <Lock fontSize="small" />
          </div>
        )}

        {swipeDirection && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '120px',
              color: swipeDirection === 'right' ? 'green' : 'red',
              opacity: Math.min(1, Math.abs(position.x) / 100),
              transition: 'opacity 0.1s ease',
            }}
          >
            {swipeDirection === 'right' ? (
              <CheckCircle fontSize="inherit" />
            ) : (
              <Cancel fontSize="inherit" />
            )}
          </div>
        )}

        {children}
      </div>
    )
  );
};

export default SwipeableCard;
