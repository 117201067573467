import React from 'react';
import { Container, Typography,Card, Button } from '@mui/material';
import FaceOffPage from './FaceOffPage';

const NotFoundPage = () => {
    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'top', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <Card sx={{ m: 3, p: 4, boxShadow: 3, borderRadius: 2 }}>
                <Typography variant="h1" component="h2" gutterBottom>
                    404
                </Typography>
                <Typography variant="h5" component="h5" gutterBottom>
                    Page Not Found
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    The page you are looking for does not exist or has been moved. Rank some games instead.
                </Typography>
                <Button variant="contained" color="primary" href="/faceoff">
                    Rank Your Games
                </Button>
            </Card>
        </Container>
    );
};

export default NotFoundPage;
