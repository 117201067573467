import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        login();
    }, []);

    // Function to simulate user login
    const login = () => {
        const fetchProfile = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/profile`, { withCredentials: true });
                setUser(data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    };

    // Function to simulate user logout
    const logout = () => {
        setUser(null);
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};
