import { useUser } from '../UserContext';
import TabbedPageLayout from '../components/TabbedPageLayout';
import Login from './Login';
import FaceOffImageList from '../components/FaceOffImageList';

const MyTierListsPage = () => {
  const { user } = useUser();

  if (!user) {
    return <Login />;
  }

  const tabs = [
    {
      label: 'My Tier Lists',
      title: 'My Tier Lists',
      subtitle: 'View your personal tier lists.',
      content:  <FaceOffImageList
      dataSource={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards/${user.id}`}
      linkTo={`UserTierList/id/`}
      itemDescriptor="leaderboard"
      metricDescriptor="% ranked"
    />,
    },
  ];

  return (
    <TabbedPageLayout
      topCardProps={{
        title: 'Tier Lists',
        body: ['See games ranked by tier'],
      }}
      tabs={tabs}
    />
  );
};

export default MyTierListsPage; 