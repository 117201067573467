const routes = [
  { path: '/Logout', element: 'Logout', addToSiteMap: true },
  { path: '/MyProfile', element: 'Profile', addToSiteMap: true },
  { path: '/Login', element: 'Login', addToSiteMap: true },
  { path: '/', element: 'HomePage', addToSiteMap: true },
  { path: '/UserTierList/id/:id', element: 'TierListPage', addToSiteMap: false },
  { path: '/UserTierList/criterion_name/:criterion_name', element: 'TierListPage', addToSiteMap: false },
  { path: '/MyCompetitors', element: 'MyCompetitors', addToSiteMap: true },
  { path: '/MyTierLists', element: 'MyTierListsPage', addToSiteMap: true },
  { path: '/competitor/:corelation_id', element: 'CompetitorPage', addToSiteMap: false },
  { path: '/criterion/:criterionId', element: 'CriterionPage', addToSiteMap: false },
  { path: '/FaceOff', element: 'FaceOffPage', addToSiteMap: true },
  { path: '/TierListsPage', element: 'LeaderboardsPage', addToSiteMap: true },
  { path: '/TierList/id/:id', element: 'LeaderboardPage', addToSiteMap: false },
  { path: '/TierList/criterion_name/:criterion_name', element: 'LeaderboardPage', addToSiteMap: false },
  { path: '/About', element: 'AboutPage', addToSiteMap: true },
  { path: '/SwipeTestPage', element: 'SwipeTestPage', addToSiteMap: false },
  { path: '/UserLeaderboardPage', element: 'UserLeaderboardPage', addToSiteMap: true },
  { path: '/User/:id', element: 'UserPage', addToSiteMap: true },
  { path: '*', element: 'NotFoundPage', addToSiteMap: true }
];

module.exports = routes; // Use CommonJS export
