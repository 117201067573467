import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({
  title,
  description = `Ready to take your love for gaming to the next level? Our app lets you create personalized gaming tier lists by rating games Tinder-style—quick, fun, and all about your favorite genres. Battle it out in your own rankings, discover hidden gems, and see how your list stacks up against fellow gamers worldwide. Whether you're hunting for new games or debating the GOAT, this is where the real players rank up. 🚀 Start swiping, start ranking, and let the games begin! 🎯✨`,
  image = "/images/whichisthebest.png",
  url = window.location.href,
  keywords = "best, tier list, ranking, compare, community, social media"
}) => {
  return (
    <Helmet>
      {/* Title */}
      <title>{title}</title>

      {/* Meta Description */}
      {description && <meta name="description" content={description} />}

      {/* Open Graph Tags */}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content="website" />

      {/* Twitter Card Tags */}
      {/*twitterHandle && <meta name="twitter:creator" content={twitterHandle} />*/}
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      <link rel="canonical" href={url} />

      {/* Meta Keywords */}
      {keywords && <meta name="keywords" content={keywords} />}

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />

      {/* Structured Data for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": title,
          "description": description,
          "image": image,
          "url": url,
          "keywords": keywords.split(',').map(keyword => keyword.trim())
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
