import React, { useState } from 'react';
import { Chip, Tooltip, IconButton, Paper, ClickAwayListener, Popper, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  RedditShareButton,
} from 'react-share';
import ShareIcon from '@mui/icons-material/Share';

/**
 * ShareButtons Component
 * 
 * Renders a share chip and social media share buttons using the provided title and URL.
 * 
 * @param {Object} props - The properties for the share buttons.
 * @returns {JSX.Element} The rendered share buttons.
 */
const ShareButtons = ({ title, url, fetchShareData, loading, error, displayName="Share this matchup" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChipClick = (event) => {
    if (!url) {
      fetchShareData();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      alert(`Copied URL ${url} to clipboard!`);
    });
  };

  return (
    <>
      {/* Share Chip */}
      <Chip
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {loading ? (
              <CircularProgress size={16} />
            ) : error ? (
              'Retry'
            ) : (
              <>
                <ShareIcon style={{ marginRight: 4 }} />
                {displayName}
              </>
            )}
          </div>
        }
        onClick={handleChipClick}
        sx={{
          backgroundColor: 'lightgray',
          '&:hover': { backgroundColor: 'darkgray' },
          cursor: 'pointer',
        }}
      />

      <Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ p: 1, display: 'flex', gap: 1 }}>
            <Tooltip title="Copy URL to clipboard" arrow>
              <IconButton
                onClick={handleCopyToClipboard}
                sx={{
                  backgroundColor: 'lightgray',
                  '&:hover': { backgroundColor: 'darkgray' },
                  borderRadius: '50%',
                  width: 32,
                  height: 32,
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Facebook" arrow disableInteractive>
              <div>
                <FacebookShareButton url={url} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
            </Tooltip>
            <Tooltip title="Share on Twitter" arrow disableInteractive>
              <div>
                <TwitterShareButton url={url} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </Tooltip>
            <Tooltip title="Share on Reddit" arrow disableInteractive>
              <div>
                <RedditShareButton url={url} title={title}>
                  <RedditIcon size={32} round />
                </RedditShareButton>
              </div>
            </Tooltip>
            <Tooltip title="Share on WhatsApp" arrow disableInteractive>
              <div>
                <WhatsappShareButton url={url} title={title}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </Tooltip>
            <Tooltip title="Share via Email" arrow disableInteractive>
              <div>
                <EmailShareButton url={url} subject={title}>
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </div>
            </Tooltip>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ShareButtons; 