import React from 'react';
import { Box, Typography, Tooltip, Link } from '@mui/material';

const CriterionTag = ({ criterion, styles, tooltipText, fromCompetitorId, relevancyPercentage }) => {
  // Default data if none is provided
  const defaultStyles = {
    transition_1_color: "#4a4a4a",
    transition_2_color: "#141414",
    emoji: "",
    font_color: "#FFFFFF",
  };

  // Use provided styles or default styles
  const stylesToUse = styles || defaultStyles;

  // Styles for the 3D tag, using stylesToUse
  const tagStyle = {
    display: 'inline-block',
    padding: '7px 10px',
    borderRadius: '8px',
    background: `radial-gradient(circle, ${stylesToUse.transition_1_color} 20% , ${stylesToUse.transition_2_color} 80%)`,
    color: stylesToUse.font_color,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    cursor: 'pointer',
    textAlign: 'center',
    mb: 1,
  };

  // URL for criterion details and FaceOff
  const criterionDetailsUrl = `/TierList/criterion_name/${criterion}`;
  const faceOffUrl = `/faceoff?criterion_name=${criterion}&primary_opponent_id=${fromCompetitorId}&mode=primary`;

  // Truncate the tooltip text to 125 characters
  const TRUNCATE_LENGTH = 600;

  const truncatedTooltipText = relevancyPercentage != null
  ? `${100 - relevancyPercentage}% Relevant\n${tooltipText?.length > TRUNCATE_LENGTH 
      ? `${tooltipText.substring(0, TRUNCATE_LENGTH)}...`
      : tooltipText}`
  : tooltipText;





  return (
    <div>
      <Tooltip
        title={
      <React.Fragment>
        {/* Tooltip text with relevancyPercentage and line breaks */}
        <Typography variant="caption" sx={{ whiteSpace: 'pre-line', fontSize: '0.8rem', color: 'white' }}>
          {truncatedTooltipText || "More information"}
        </Typography>
        
        {/* More Details link, made more visible on gray background */}
        <Link 
          href={criterionDetailsUrl} 
          underline="hover" 
          sx={{ color: 'primary.light', fontSize: '0.8rem', mt: 1, display: 'block' }} // Ensure it's visible
        >
          More Details
        </Link>
      </React.Fragment>

        }
        arrow
        sx={{
          tooltip: {
            bgcolor: 'grey.700',
            color: 'white',
          }
        }}
      >
        <Box>
          {/* Clicking on the tag redirects based on fromCompetitorId */}
          <Link 
            href={fromCompetitorId ? faceOffUrl : `/faceoff?criterion_name=${criterion}`} 
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Typography sx={tagStyle}>
              {stylesToUse.emoji} {criterion}
            </Typography>
          </Link>
        </Box>
      </Tooltip>
    </div>
  );
};

export default CriterionTag;
