import React from 'react';
import TabbedPageLayout from '../components/TabbedPageLayout';
import FaceOffImageList from '../components/FaceOffImageList';
import FaceOffDataTable from '../components/FaceOffDataTable';
import SEO from '../components/SEO';

const LeaderboardsPage = () => {
  document.title = 'Tier Lists';

  const topCardProps = {
    image: null, // No image for the top card
    title: 'Tier Lists',
    body: [
      `Explore Tier Lists for the best ${process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors'} against each ${
        process.env.REACT_APP_CRITERION_DESCRIPTOR || 'Criterion'
      }.`,
    ],
  };


  const tabs = [
    {
      label: 'Tier Lists',
      title: 'Tier Lists',
      subtitle: `A-B-C-D-E-F rating for each game, broken down by ${process.env.REACT_APP_CRITERION_DESCRIPTOR}`,
      content: (
        <>
        <FaceOffImageList
          dataSource={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards`}
          linkTo="TierList/id/"
          itemDescriptor="leaderboard"
          metricDescriptor="% games ranked"
        />
        </>
      ),
    },
    {
      label: 'Statistics',
      title: 'Statistics',
      subtitle: 'More detailed statistics about tier lists. Rank and share each entry.',
      content: (
        <FaceOffDataTable
          endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards`}
          headCells={[
                {
                  id: 'criterion_emoji',
                  label: 'Emoji',
                  type: 'text', // For FaceOffTableV2 compatibility
                  numeric: false,
                },
                {
                  id: 'name',
                  label: 'Game',
                  type: 'link', // Specifies that this column is a link
                  numeric: false,
                  properties: {
                    page_name: 'TierList/id/',
                    link_data: 'corelation_id',
                  },
                },
                {
                  id: 'seeded_competitors',
                  label: '# Ranked',
                  type: 'numeric', // Numeric field
                  numeric: true,
                  hideMobile: true,
                },
                {
                  id: 'unseeded_competitors',
                  label: '# Unranked',
                  type: 'numeric', // Numeric field
                  numeric: true,
                  hideMobile: true,
                },
                {
                  id: 'relevancy_metric',
                  label: '% Games Ranked',
                  type: 'numeric', // Numeric field
                  numeric: true,
                },
              ]}
            defaultSortField="relevancy_metric"
            defaultSortOrder="desc"
          />
      ),
    },
  ];

  return (
    <>
      <SEO
        title="Leaderboards - All Competitors"
        description="Explore the list of all leaderboards and find the best competitors across various criteria."
        url={window.location.href}
      />
      <TabbedPageLayout topCardProps={topCardProps} tabs={tabs} />
    </>
  );
};

export default LeaderboardsPage;
