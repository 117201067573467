import React, { useState, useEffect } from 'react';
import SwipeableCard from '../components/SwipeableCard';
import CompetitorDetails from '../components/CompetitorDetails';
import axios from 'axios'; // For API requests

const SwipeTestPage = () => {
  const [primaryCompetitor, setPrimaryCompetitor] = useState(null);
  const [challengerCompetitor, setChallengerCompetitor] = useState(null);

  const handleSwipePrimary = (direction) => {
    console.log(`Primary Competitor swiped ${direction}`);
  };

  const handleSwipeChallenger = (direction) => {
    console.log(`Challenger Competitor swiped ${direction}`);
  };

  const fetchCompetitors = async () => {
    try {
      const primaryCompetitorId = 30;
      const challengerCompetitorId = 2910;

      const primaryResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/competitor/${primaryCompetitorId}`
      );
      const challengerResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/competitor/${challengerCompetitorId}`
      );

      setPrimaryCompetitor(primaryResponse.data.competitor);
      setChallengerCompetitor(challengerResponse.data.competitor);
    } catch (err) {
      console.error('Error fetching competitor data:', err);
    }
  };

  useEffect(() => {
    fetchCompetitors();
  }, []);

  if (!primaryCompetitor || !challengerCompetitor) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#eaeaea',
        }}
      >
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        padding: '20px',
        backgroundColor: '#eaeaea',
      }}
    >
      <SwipeableCard id={1} onSwipe={handleSwipePrimary} disabled={true}>
        <CompetitorDetails competitorData={primaryCompetitor} />
      </SwipeableCard>
      <SwipeableCard id={2} onSwipe={handleSwipeChallenger}>
        <CompetitorDetails competitorData={challengerCompetitor} />
      </SwipeableCard>
    </div>
  );
};

export default SwipeTestPage;
