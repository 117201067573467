import React from 'react';
import { Chip } from '@mui/material';
import { CompareArrows } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const RankChip = ({ label = "Rank", competitorId, criterionName }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const buildQueryParams = () => {
    const params = new URLSearchParams();
    if (competitorId) params.append('primary_opponent_id', competitorId);
    if (criterionName) params.append('criterion_name', criterionName);
    return params.toString();
  };

  const handleClick = () => {
    const linkTo = `/faceoff?${buildQueryParams()}`;
    if (location.pathname + location.search === linkTo) {
      // If the link is the same, force a reload
      navigate(0);
    } else {
      navigate(linkTo);
    }
  };

  return (
    <Chip
      icon={<CompareArrows />}
      label={label}
      onClick={handleClick}
      sx={{
        backgroundColor: 'lightgray',
        '&:hover': { backgroundColor: 'darkgray' },
        textDecoration: 'none',
        m: 2,
      }}
      clickable
    />
  );
};

RankChip.propTypes = {
  label: PropTypes.string,
  competitorId: PropTypes.string,
  criterionName: PropTypes.string,
};

export default RankChip;
