import React, { useState, useEffect } from 'react';
import TabbedPageLayout from '../components/TabbedPageLayout';
import { Card, Typography, Box } from '@mui/material';
import TopCompetitorsTable from '../components/TopCompetitorsTable';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';

const UserPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useUser();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/GetUserProfile/${id}`);
        setUserData({
          display_name: response.data.display_name,
          avatar: response.data.external_tiny_avatar_URL,
        });
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch user data.');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [id]);

  const tabs = [
    {
      label: 'Best Games',
      title: `${userData ? userData.display_name : "User"}'s Top Ranked Games`,
      subtitle: 'Discover the best games ranked by users.',
      content: <TopCompetitorsTable pid={id} />,
    },
    /*
    {
      label: 'Compare',
      content: (
        <Card sx={{ mt: 2, p: 2 }}>
          {user ? (
            <>
              <Typography variant="h4" component="div">
                Details
              </Typography>
              <Typography variant="body2" component="div">
                This tab provides detailed information about the content presented on this page. Feel free to explore!
              </Typography>
            </>
          ) : (
            <Login />
          )}
        </Card>

      ),
    },*/
  ];

  const topCardContent = () => {
    if (loading) {
      return (
        <Typography variant="h4" align="center">
          Loading user data...
        </Typography>
      );
    }

    if (error) {
      return (
        <Typography variant="h4" align="center" color="error">
          {error}
        </Typography>
      );
    }

    if (userData) {
      return (
        <Card sx={{ padding: 3, mt: 2, textAlign: 'center' }}>
          <Typography variant="h4">{userData.display_name}</Typography>
          <Box
            component="img"
            src={userData.avatar}
            alt="profile"
            sx={{ width: 100, borderRadius: '50%', my: 2 }}
          />
        </Card>
      );
    }

    return null;
  };

  return (
    <TabbedPageLayout
      customTopCard={<Box sx={{ textAlign: 'center', py: 2 }}>{topCardContent()}</Box>}
      tabs={tabs}
    />
  );
};

export default UserPage;
