import FaceOffDataTable from './FaceOffDataTable';

const headCells = [
  {
    id: 'large_image_url',
    label: 'Image',
    type: 'image', // New type for images
    properties: {} // No additional properties needed
  },
  {
    id: 'name',
    label: 'Game',
    type: 'link', // New type for links
    hideMobile:true,
    properties: { page_name: 'competitor/', link_data: 'corelation_id' } // Properties for link generation
  },
  {
    id: 'leaderboard_name',
    label: 'Leaderboard',
    type: 'link', // New type for links
    properties: { defaultEmoji: '🏆', page_name: 'TierList/criterion_name/', link_data: 'criterion' } // Properties for link generation
  },
  {
    id: 'relevancy_metric',
    label: 'Elo Rating',
    type: 'text', // Default type; can be omitted
    hideMobile:true,
    properties: {} // No additional properties
  },
  {
    id: 'tier',
    label: 'Tier',
    type: 'tier', // Default type; can be omitted
    properties: {} // No additional properties
  },
  {
    id: 'faceoff',
    label: '',
    type: 'faceoff', // New type for faceoff links
    properties: { criterion: 'criterion', competitor: 'corelation_id' } // Faceoff-specific properties
  },
  {
    id: 'share',
    label: '',
    type: 'share', // New type for sharing
    properties: {
      primaryCompetitorId: 'corelation_id',
      primaryCompetitorName: 'name',
      primaryCompetitorImage: 'large_image_url',
      criterion: 'criterion'
    }
  }
];

const TopCompetitorsTable = ({ profileId, pid }) => {
  // Construct the endpoint dynamically based on the provided parameters
  const endpoint = `${process.env.REACT_APP_SERVER_URL}/GetBestOfCompetitors` +
                   (pid ? `/pid/${pid}` : profileId ? `/${profileId}` : '');

  return (
    <FaceOffDataTable
      endpoint={endpoint}
      headCells={headCells}
      defaultSortField="relevancy_metric"
      defaultSortOrder="desc"
      enableSearch={true}
    />
  );
};


export default TopCompetitorsTable;
