import React, { useState } from 'react';
import axios from 'axios';
import ShareButtons from './ShareButtons';

/**
 * SocialShare Component
 * 
 * Provides social media sharing buttons and a "Copy to Clipboard" option for sharing a URL.
 * Automatically fetches the sharing data when clicked.
 * 
 * @param {Object} params - The parameters for fetching share data.
 * @returns {JSX.Element} The rendered social sharing component.
 */
const SocialShare = ({
  primaryCompetitorId,
  primaryCompetitorName,
  primaryCompetitorImage,
  challengerCompetitorId,
  challengerCompetitorName,
  criterion,
  displayName = 'Share this matchup',
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shareData, setShareData] = useState(null);

  /**
   * Handles fetching the share data from the server.
   */
  const fetchShareData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/share_faceoff_match`, {
        primary_competitor_id: primaryCompetitorId,
        primary_competitor_name: primaryCompetitorName,
        primary_competitor_image: primaryCompetitorImage,
        challenger_competitor_id: challengerCompetitorId,
        challenger_competitor_name: challengerCompetitorName,
        criterion,
      });

      setShareData(response.data);
    } catch (err) {
      console.error('Error fetching share data:', err);
      setError('Failed to load share data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Popper for Share Options */}
      <ShareButtons
        title={shareData ? shareData.title : ''}
        url={shareData ? shareData.url : ''}
        fetchShareData={fetchShareData}
        loading={loading}
        error={error}
        displayName={displayName}
      />

      {error && (
        <div style={{ color: 'red', marginTop: '8px' }}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default SocialShare;
