import React, { useState, useEffect, useRef } from 'react';
import { 
  Grid, 
  Card, 
  Typography, 
  Button, 
  Tooltip, 
  Box, 
  CircularProgress,
  Alert
} from '@mui/material';

import { Link } from 'react-router-dom';
import { 
  CheckCircle, 
  RadioButtonUnchecked,
  ArrowBack,
  ArrowForward,
  Cancel as CrossIcon,
  SkipNext
} from '@mui/icons-material';
import axios from 'axios';

// Custom Components
import CriterionCard from './CriterionCard';
import CompetitorDetails from './CompetitorDetails';
import SocialShare from './chips/SocialShare';
import SwipeableCard from './SwipeableCard';
import Login from '../pages/Login';
import { useUser } from '../UserContext';
import ChallengerMatchesTable from './ChallengerMatchesTable'
import LoginOverlay from './Login/LoginOverlay';
import RankChip from './chips/RankChip';
import SEO from './SEO';

const FaceOff = ({initPrimaryOpponent, initCriteriaName, shareid, mode }) => {

    const { user } = useUser();
  
    // State Variables
    const [primaryCompetitor, setPrimaryCompetitor] = useState(null);
    const [challengerCompetitor, setChallengerCompetitor] = useState(null);
    const [nextPrimaryCompetitorId, setNextCompetitorId] = useState(null);
    const [faceOffInstanceId, setFaceOffInstanceId] = useState(null);
    const [leaderboardId, setLeaderboardId] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [criterion, setCriterionData] = useState(null);
    const [competitorSelections, setCompetitorSelections] = useState(null);
    const [initializing, setInitializing] = useState(true);
    const [isFindingMatch, setIsFindingMatch] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [consecutiveWins, setConsecutiveWins] = useState(0);
    const [lastWinner, setLastWinner] = useState(null);
    const [disableVoting, setDisableVoting] = useState(false);
    const previousCriterion = useRef(criterion);
  
  // Fetch FaceOff Competitor Data
    const fetchFaceOffCompetitor = async (primaryOpponentId=null,criterionName=null, shareid=null, mode) => {

        try {
            setError(null);
            setInfo(null);
            let params = [];

            if (criterionName) params.push(`criterion_name=${criterionName}`);
            if (primaryOpponentId) params.push(`primary_opponent_id=${primaryOpponentId}`);
            if (mode) params.push(`mode=${mode}`);
            if (shareid) params.push(`shareid=${shareid}`);

            const url = `${process.env.REACT_APP_SERVER_URL}/faceoff?${params.join('&')}`;
            console.info(`Retrieving matches from server: ${url}`);
            const response = await axios.get(url, { withCredentials: true });

            if (response.data.success === true) {
                console.info("Match found!");
                console.log("response.data", response.data);
                const faceOffDetails = response.data.data.fullFaceOffDetails;
                
                setChallengerCompetitor(faceOffDetails.primaryCompetitor);
                setPrimaryCompetitor(faceOffDetails.challengerCompetitor);
                setIsAuthenticated(response.data.isAuthenticated);
                if (faceOffDetails.nextSelectedOpponent) {
                    setNextCompetitorId(faceOffDetails.nextSelectedOpponent.competitor);
                }
                setFaceOffInstanceId(faceOffDetails.faceOffDetails.fi_id);
                setLeaderboardId(faceOffDetails.faceOffDetails.leaderboard);

                const updatedCriterion = {
                    name: faceOffDetails.faceOffDetails.criterion,
                    leaderboard_description: faceOffDetails.faceOffDetails.description,
                    image: faceOffDetails.faceOffDetails.avatar_url,
                    transition_1_color: faceOffDetails.faceOffDetails.transition_color_1,
                    transition_2_color: faceOffDetails.faceOffDetails.transition_color_2,
                    emoji: faceOffDetails.faceOffDetails.emoji,
                    font_color: faceOffDetails.faceOffDetails.font_color,
                };
                setCompetitorSelections(faceOffDetails.selections);
                if (updatedCriterion.name !== previousCriterion.current?.name) {
                    setConsecutiveWins(0);
                }
                setCriterionData(updatedCriterion);
                previousCriterion.current = updatedCriterion;
                if (response.data.info !== '') {
                    setInfo(response.data.info);
                }

                const primaryHasCompetitor = faceOffDetails.primaryCompetitor.has_competitor;
                const challengerHasCompetitor = faceOffDetails.challengerCompetitor.has_competitor;

                // Set disableVoting based on has_competitor attributes
                setDisableVoting(!primaryHasCompetitor || !challengerHasCompetitor);

                if (!primaryHasCompetitor || !challengerHasCompetitor) {
                    const missingGames = [];
                    if (!primaryHasCompetitor) missingGames.push(faceOffDetails.primaryCompetitor.name);
                    if (!challengerHasCompetitor) missingGames.push(faceOffDetails.challengerCompetitor.name);
                    setInfo(`You cannot vote on the following games as they are not in your Steam library: ${missingGames.join(', ')}`);
                }
            } else {
                setError(response.data.error);
            }
        } catch (err) {
            setError(`You do not have any games to rank ${criterionName ? `for ${criterionName}` : "you."}. ` + err.message);
        } finally {
            setInitializing(false);
        }
    };

    useEffect(() => {
        setInitializing(true);
        fetchFaceOffCompetitor(initPrimaryOpponent, initCriteriaName, shareid, mode);
    }, [initPrimaryOpponent, initCriteriaName, mode, shareid]);

    const handleSubmit = async (selectedCompetitor = bestCompetitor) => {
        setError(null);
        setIsFindingMatch(true);

        const data = {
            faceOffInstanceId,
            criterionId: criterion.name,
            outcome: selectedCompetitor ? 2 : 1,
            winningCompetitorId: selectedCompetitor,
            losingCompetitorId:
                selectedCompetitor === primaryCompetitor.corelation_id
                    ? challengerCompetitor.corelation_id
                    : primaryCompetitor.corelation_id,
            ...(nextPrimaryCompetitorId !== null && { bestCompetitor }),
            leaderboardId,
            mode,
            primaryCompetitorId: primaryCompetitor.corelation_id,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/submitfaceoff`, data, {
                withCredentials: true,
            });

            if (response.status === 200) {
                console.log("Submission successful. Refreshing state...");
                console.log(`Last winner:${lastWinner}, Winner:${selectedCompetitor}`);
                if (selectedCompetitor && lastWinner === selectedCompetitor) {
                    console.log("Same winner as last time. Incrementing streak.");
                    setConsecutiveWins(prev => prev + 1);
                } else if (selectedCompetitor) {
                    console.log("New win streak for ", selectedCompetitor);
                    setConsecutiveWins(1);
                    setLastWinner(selectedCompetitor);
                }


                await fetchFaceOffCompetitor(selectedCompetitor && consecutiveWins === 3 ? challengerCompetitor.corelation_id : selectedCompetitor || primaryCompetitor.corelation_id, criterion.name, mode); 
            } else {
                setError("Failed to submit the faceoff.");
            }
        } catch (err) {
            setError("Error submitting faceoff: " + err.message);
        } finally {
            setBestCompetitor(null);
            setIsFindingMatch(false);
        }
    };

    const [bestCompetitor, setBestCompetitor] = useState(null);
    const [autoSubmit, setAutoSubmit] = useState(() => {
        const savedAutoSubmit = localStorage.getItem('autoSubmit');
        return savedAutoSubmit === null ? true : savedAutoSubmit === 'true';
    });

    const handleSwipe = (direction,competitorId) => {
        console.log(`Swiped ${competitorId} ${direction}`);
        switch(direction) {
            case "left" : 
                handleSelectBest(primaryCompetitor.corelation_id, true); 
                break;
            case "right" : 
                handleSelectBest(competitorId, true); break;
            case "down" : handleSubmit() ; break;
            default: 
                console.log("reset"); break;
        }
      };

      const handleSelectBest = (competitorId, isSwipe=false) => {
        // Determine the new best competitor
        console.log("Selected competitor ", competitorId);
    
        // Update the state for best competitor
        setBestCompetitor(competitorId);
    
        if ((autoSubmit || isSwipe) && isAuthenticated ) {
            handleSubmit(competitorId); // Pass the new competitor directly
        }
    };

    const middleButtonText = bestCompetitor ? "Submit Winner" : "Skip";

    // Toggle auto-submit state and save it to localStorage
    const handleAutoSubmitChange = () => {
        const newAutoSubmit = !autoSubmit;
        setAutoSubmit(newAutoSubmit);
        localStorage.setItem('autoSubmit', newAutoSubmit);
    };

    useEffect(() => {
        console.log(`Consecutive Wins for competitor ${lastWinner} : ${consecutiveWins}`);
    }, [consecutiveWins, lastWinner]);

    // Render initializing state
    if (initializing) {

        return (
            <Box>
                <SEO 
                    title={`FaceOff - Compare ${criterion?.name || ''} Games`} 
                    description="Join the ultimate showdown and determine which game reigns supreme!" 
                />
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Card sx={{ p: 2 }}>
                        <Typography variant="h4">Finding games to rank...</Typography>
                        <CircularProgress size={80} />
                    </Card>
                </Box>
            </Box>
        );
    }

    // Render error or info
    if (error) {

        return (
        <Box sx={{ mt: 4 }}>
            <Card sx={{ p: 2 }}>
            <Alert severity="error">{error}</Alert>
            <Typography variant="h5" sx={{ mt: 2 }}>
                You may want to <Link to="/faceoff?mode=personalised">start a new matchup</Link> or explore a <Link to="/faceoff?mode=random">random matchup</Link>.
            </Typography>
            </Card>
        </Box>
        );
    }
    
    return (
        <Card sx={{ p: 1 }}>

            <LoginOverlay
                isDisplayed={!isAuthenticated}
                message="This matchup is just a preview. You must sign in to rank your games."
            />
            <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: { xs: 'center' } }}>
                Which is the better <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/TierList/criterion_name/${criterion.name}`}>'{criterion.name}'</Link> Steam Game?
            </Typography>
            {info && (
            <Box sx={{ mt: 4 }}>
                <Alert severity="info">{info}</Alert>
            </Box>
            )}

            <Grid container spacing={0}>
                <Grid item xs={12} sm={5} sx={{ mt: 2, mb: 2 }}>
                    {primaryCompetitor && (
                        <Box sx={{ position: 'relative' }}>
                            <CompetitorDetails competitorData={primaryCompetitor} />
                            {consecutiveWins > 0 && lastWinner === primaryCompetitor.corelation_id && (
                                <Box 
                                    sx={{ 
                                        position: 'absolute', 
                                        top: 0, 
                                        right: 0, 
                                        bgcolor: 'success.main', 
                                        color: 'white', 
                                        borderRadius: '50%', 
                                        p: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption">{consecutiveWins}/4</Typography>
                                    <CheckCircle sx={{ ml: 0.5 }} />
                                </Box>
                            )}
                        </Box>
                    )}
                </Grid>

                {criterion && (
                    <Grid 
                        item 
                        xs={12} 
                        sm={2} 
                        sx={{ 
                            mt: 2, 
                            mb: 2, 
                            textAlign: 'center',
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        <CriterionCard
                            name={criterion.name}
                            description={criterion.leaderboard_description}
                            avatar_url={criterion.image || ""}
                            showDescription={false}
                            criterionStyles={
                                criterion.transition_1_color && criterion.transition_2_color
                                    ? {
                                        transition_1_color: criterion.transition_1_color,
                                        transition_2_color: criterion.transition_2_color,
                                        emoji: criterion.emoji,
                                        font_color: criterion.font_color,
                                    }
                                    : undefined
                            }
                        />
                        {isFindingMatch && (
                            <CircularProgress size={80} sx={{ mt: 2 }} />
                        )}
                    </Grid>
                )}

                <Grid item xs={12} sm={5} sx={{ mt: 2, mb: 2 }}>
                    
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <CompetitorDetails competitorData={challengerCompetitor} />
                    </Box>

                    {/* xs display */}
                    <Box sx={{ display: { xs: "block", sm: "none" }, border: '1px dashed', borderRadius: '8px', pt:2, pb:2 }}>
                        {isFindingMatch && (
                            <CircularProgress size={180} />
                        )}
                        {challengerCompetitor && !isFindingMatch && (
                            <SwipeableCard
                                key={challengerCompetitor.corelation_id} // Add key to reset component
                                id={challengerCompetitor.corelation_id}
                                onSwipe={handleSwipe}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                <CompetitorDetails competitorData={challengerCompetitor} />
                            </SwipeableCard>
                        )}

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                            }}
                        >
                            {!disableVoting && isAuthenticated && (
                                <>
                                    {/* Swipe Left Instruction */}
                                    <Box sx={{ textAlign: 'center', flex: 1 }}>
                                        <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                            Swipe Left
                                        </Typography>
                                        <CrossIcon color="error" sx={{ fontSize: 50, cursor: 'pointer' }} />
                                        <ArrowBack sx={{ fontSize: 50, color: 'gray', cursor: 'pointer' }} />
                                        <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                            Worse
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        {isAuthenticated && (
                                            <Button 
                                                variant="contained" 
                                                color="secondary" 
                                                onClick={() => { handleSubmit() }} 
                                                sx={{ 
                                                    borderRadius: '50%', 
                                                    width: 80, // Set width
                                                    height: 80 // Set height to match width
                                                }} 
                                                disabled={isFindingMatch} // Disable button while finding match
                                            >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <SkipNext /> {/* Icon above text */}
                                                    <Typography variant="body2">Next Match</Typography>
                                                </Box>
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Swipe Right Instruction */}
                                    <Box sx={{ textAlign: 'center', flex: 1 }}>
                                        <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                            Swipe Right
                                        </Typography>
                                        <ArrowForward sx={{ fontSize: 50, color: 'gray', cursor: 'pointer' }} />
                                        <CheckCircle color="success" sx={{ fontSize: 50, cursor: 'pointer' }} />
                                        <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                            Better
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={0} sx={{ mt: 0, mr: 0, alignItems: 'center' }}>
                <Grid item xs={12} sm={5} sx={{ display: {xs:'none',sm:'flex'}, justifyContent: 'center', mb: 2 }}>
                    <Tooltip title={!disableVoting ? '' : 'You are not eligible to vote on this competitor.'}>
                        <span>
                            <Button
                                variant={bestCompetitor === primaryCompetitor.corelation_id ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleSelectBest(primaryCompetitor.corelation_id)}
                                startIcon={bestCompetitor === primaryCompetitor.corelation_id ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                {primaryCompetitor.name}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>

                {/* Middle button with Auto Submit icon button */}
                <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', mb: 2, flexDirection: 'column', alignItems: 'center' }}>
                    {isAuthenticated ? (
                        <>
                            <Button variant="contained" sx={{display:{xs:'none',sm:'flex'}}} color="secondary" onClick={() => {handleSubmit()}}>
                                {middleButtonText}
                            </Button>
                            <Button
                                sx={{margin:1, display: {xs:'none',sm:'flex'}}}
                                variant={autoSubmit ? 'contained' : 'outlined'}
                                color="secondary"
                                onClick={handleAutoSubmitChange}
                                startIcon={autoSubmit ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                Auto-submit on selection
                            </Button>
                        </>
                    ) : (
                        <Login msg={"This matchup is just a preview. You must sign in to rank your games."} />
                    )}
                <Box
                    sx={{
                        display: 'flex', // Enables flexbox layout
                        justifyContent: 'center', // Centers the components horizontally
                        alignItems: 'center', // Aligns components vertically
                        gap: 2, // Adds spacing between the components
                        mt: 2, // Optional: Adds top margin
                    }}
                >
                    <RankChip criterionName={criterion.name} label="Random Matchup" />
                    {!isFindingMatch && (
                        <SocialShare
                        primaryCompetitorId={`${primaryCompetitor.corelation_id}`}
                        primaryCompetitorName={`${primaryCompetitor.name}`}
                        primaryCompetitorImage={`${primaryCompetitor.large_image_url}`}
                        challengerCompetitorId={`${challengerCompetitor.corelation_id}`}
                        challengerCompetitorName={`${challengerCompetitor.name}`}
                        criterion={`${criterion.name}`}
                        />
                    )}
                </Box>


                </Grid>

                <Grid item xs={12} sm={5} sx={{ display: {xs:'none',sm:'flex'}, justifyContent: 'center', mb: 2 }}>
                    <Tooltip title={!disableVoting ? '' : 'You are not eligible to vote on this competitor'}>
                        <span>
                            <Button
                                variant={bestCompetitor === challengerCompetitor.corelation_id ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => handleSelectBest(challengerCompetitor.corelation_id)}
                                startIcon={bestCompetitor === challengerCompetitor.corelation_id ? <CheckCircle /> : <RadioButtonUnchecked />}
                                disabled={disableVoting || !isAuthenticated}
                            >
                                {challengerCompetitor.name}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            {user && user.accountType === 'admin' && competitorSelections && (
        <Card sx={{ mt: 3, p: 2 }}>
          <Typography variant="h4" component="div">
            Challenger matches
          </Typography>
          <Typography variant="body2" component="div">
            Below shows the results of the matching algorithm for potential challenger competitors against "{primaryCompetitor.name}". All four scores are weighted to form a total score, where the lowest scoring non-excluded competitor gets picked. Games will be excluded if previously matched or if they exceed a given threshold value.
          </Typography>
          <ChallengerMatchesTable rows={competitorSelections} /> 

        </Card>
      )}
        </Card>
    );
};

export default FaceOff;
