import React, { useState } from 'react';
import { Typography } from '@mui/material';

function Login({ msg, hideMsg = false }) {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [openInSameWindow, setOpenInSameWindow] = useState(true); // Toggle state

  const handleLogin = () => {
    return `${SERVER_URL}/auth/steam?returnTo=${encodeURIComponent(window.location.href)}`;
  };

  const openLoginWindow = (e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    const loginUrl = handleLogin();

    if (openInSameWindow) {
      // Open in the same window/tab
      window.location.href = loginUrl;
    } else {
      // Open in a popup
      const popup = window.open(
        loginUrl,
        '_blank', // Open in a new window
        'width=600,height=700,scrollbars=yes,resizable=yes' // Configure popup window
      );

      if (popup) {
        const popupInterval = setInterval(() => {
          if (popup.closed) {
            clearInterval(popupInterval); // Stop checking when the popup is closed
            window.location.reload(); // Reload the page
          }
        }, 500); // Check every 500ms
      }
    }
  };

  return (
    <div>
      {!hideMsg && (
        <Typography variant="body2">{msg || "You must sign in to view this data"}</Typography>
      )}
      
      <a href={handleLogin()} onClick={openLoginWindow}>
        <img
          src="https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_02.png"
          alt="Click to login"
        />
      </a>
    </div>
  );
}

export default Login;
