import React from 'react';
import TabbedPageLayout from '../components/TabbedPageLayout';
import UserLeaderboard from '../components/UserLeaderboard'; // Example of a reusable component

const UserLeaderboardPage = () => {
  const topCardProps = {
    title: 'User Leaderboard',
    body: [
      'Compare stats with your friends!',
      'Select a user to view their rankings.',
    ],
  };

  const tabs = [
    {
      label: 'User Leaderboard',
      title: 'User Leaderboard',
      subtitle: 'Compare stats with your friends!',
      content: <UserLeaderboard />,
    },
  ];

  return (
    <TabbedPageLayout
      topCardProps={topCardProps}
      tabs={tabs}
    />
  );
};

export default UserLeaderboardPage;
