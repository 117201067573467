import React, { useState } from 'react';
import { Box, Card, CardMedia, Typography, Tabs, Tab, Container } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * TabbedPageLayout component renders a layout with a customizable top card and tabs.
 *
 * @param {Object} props - Component properties.
 * @param {Object} [props.topCardProps] - Properties for the top card.
 * @param {Object} [props.customTopCard] - Custom top card component.
 * @param {Array} props.tabs - Array of tab objects.
 * @param {string} [props.tabs[].label] - Label for the tab.
 * @param {string} [props.tabs[].title] - Title for the tab content.
 * @param {string} [props.tabs[].subtitle] - Subtitle for the tab content.
 * @param {ReactNode} [props.tabs[].content] - Content to render in the tab.
 */
const TabbedPageLayout = ({
  topCardProps = null,
  customTopCard = null,
  tabs = [],
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [renderedTabs, setRenderedTabs] = useState(new Set([0])); // Keep track of rendered tabs

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setRenderedTabs((prev) => new Set(prev).add(newValue)); // Mark the tab as rendered
  };

  return (
    <Container>
      {/* Render custom top card if provided, else render default top card */}
      {customTopCard ? (
        <Box>{customTopCard}</Box>
      ) : (
        <Card sx={{ p: 1, boxShadow: 5, borderRadius: '16px', bgcolor: '#f9f9f9', textAlign: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 1 }}>
            {topCardProps?.image && (
              <CardMedia
                component="img"
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '16px',
                  border: '2px solid #3f51b5',
                  boxShadow: 2,
                }}
                image={topCardProps.image}
                alt={`${topCardProps.title} Image`}
              />
            )}
            <Box
              sx={{
                mt: 1,
                flex: 1,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h3" component="div" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                {topCardProps?.title}
              </Typography>
              {topCardProps?.body?.map((paragraph, index) => (
                <Typography key={index} variant="body2" component="div" gutterBottom sx={{ color: '#555' }}>
                  {paragraph}
                </Typography>
              ))}
            </Box>
          </Box>
        </Card>
      )}

      {/* Tabs */}
      {tabs.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
            overflowX: 'auto', // Enable horizontal scrolling
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable" // Enable scrollable tabs
            scrollButtons="auto" // Show scroll buttons when necessary
            allowScrollButtonsMobile // Allow scroll buttons on mobile
            sx={{
              '.MuiTab-root': {
                flexShrink: 0, // Prevent shrinking of tabs
                padding: 0
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab 
                key={index} 
                label={tab.label} 
                sx={{ padding: '0 16px', margin: '0 16px' }}
              />
            ))}
          </Tabs>
        </Box>
      )}

      {/* Tab Content */}
      
      <Card sx={{ p: 1, boxShadow: 3, borderRadius: 2, mt: 4 }}>
        {tabs.map((tab, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? 'block' : 'none',
              bgcolor: 'inherit', // Inherit bgcolor from parent Card
            }}
          >
            {renderedTabs.has(index) && (
              <>
                <Box sx={{ mb: 2, textAlign: 'center', bgcolor: 'inherit' }}> {/* Inherit bgcolor */}
                  {tab.title && (
                    <Typography variant="h5" component="div" sx={{ fontWeight: '600' }}>
                      {tab.title}
                    </Typography>
                  )}
                  {tab.subtitle && (
                    <Typography variant="body1" component="div" sx={{ color: '#555', fontStyle: 'italic' }}>
                      {tab.subtitle}
                    </Typography>
                  )}
                </Box>
                {tab.content}
              </>
            )} {/* Only render content if tab is marked as rendered */}
          </Box>
        ))}
      </Card>
    </Container>
  );
};

TabbedPageLayout.propTypes = {
  topCardProps: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.arrayOf(PropTypes.string),
  }),
  customTopCard: PropTypes.node,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default TabbedPageLayout;
