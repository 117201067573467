import React from 'react';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const GenericChip = ({ label, url, icon }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };

  return (
    <Chip
      label={label}
      icon={icon}
      onClick={handleClick}
      sx={{
        backgroundColor: 'lightgray',
        '&:hover': { backgroundColor: 'darkgray' },
        textDecoration: 'none',
        m: 2,
      }}
    />
  );
};

GenericChip.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default GenericChip; 