import React, { useState } from 'react';
import { Container, Typography, Card, CardMedia, CardContent, Box, Chip, Tooltip, Button, Link } from '@mui/material';
import HttpIcon from '@mui/icons-material/Http';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CriterionTag from './CriterionTag'; // Import CriterionTag component

const CompetitorDetails = ({ competitorData, criterions = [], images = [] }) => {
  const SOURCE_DESCRIPTION = process.env.REACT_APP_COMPETITOR_SOURCE;

  const [showMore, setShowMore] = useState(false);

  // Toggle between showing more and less
  const toggleShowMore = () => setShowMore(!showMore);

  // Split criterions into the first 4 and the rest
  const displayedTags = showMore ? criterions : criterions.slice(0, 3);
  const extraTags = criterions.slice(3); // Tags beyond the first 3
  const hasExtraTags = extraTags.length > 0;

  // Collect the names of the hidden criterions for the tooltip
  const hiddenCriteriaNames = extraTags.map(criterion => criterion.criterion_name).join(', ');

  return competitorData && (
    <Container maxWidth="sm">
      <Card>
        {/* Render images if available */}
      

        <CardMedia
          component="img"
          alt={competitorData.name}
          image={competitorData.large_image_url}
          title={competitorData.name}
          sx={{ display: 'block' }} // Show on desktop only
        />
       
        <CardContent>
        {images.length > 0 && (
          <Box sx={{ 
            display: { xs: 'none', sm: 'flex' }, 
            overflowX: 'auto', 
            mb: 2, 
            '&::-webkit-scrollbar': { // Custom scrollbar styles
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}>
            <Box sx={{ display: 'flex', overflowX: 'auto', mb: 2, gap: 1 }}>
              {images.map(image => (
                <CardMedia
                  key={image.id} // Use image id as key
                  component="img"
                  alt={image.competitor}
                  image={image.image_url}
                  title={image.competitor}
                  sx={{ width: 120, height: 120, objectFit: 'cover', borderRadius: 1, boxShadow: 2 }} // Adjusted size, added border radius and shadow
                />
              ))}
            </Box>
          </Box>
        )}
        <Link
          href={`/competitor/${competitorData.corelation_id}`}
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Prevent security vulnerabilities
          style={{ textDecoration: 'none', color: 'inherit' }} // Remove underline and inherit color
        >
          <Typography variant="h5" component="div" key={competitorData.name}>
            {competitorData.name}
          </Typography>
        </Link>

          {/* Render Criterions using CriterionTag */}
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'row', 
              flexWrap: 'wrap', // Allow the tags to wrap as the view gets smaller
              gap: 1, // Reduced gap between tags
              justifyContent: 'center', // Horizontally center the tags
              alignItems: 'center', // Vertically align the tags
              textAlign: 'center',
              mb: 2 // Optional: Adjust margin between tags and other content
            }}
          >
            {displayedTags.map((criterion) => (
              <CriterionTag
                key={criterion.criterion_name}
                criterion={criterion.criterion_name}
                styles={{
                  emoji: criterion.criterion_emoji || "",
                  transition_1_color: criterion.criterion_transition_color_1 || "#4a4a4a", // Example colors
                  transition_2_color: criterion.criterion_transition_color_2 || "#141414",
                  font_color: criterion.font_color || "#FFFFFF",
                }}
                fromCompetitorId={competitorData.corelation_id}
                tooltipText={criterion.criterion_description || "No description has been added at this time."}
                relevancyPercentage={criterion.relevancy_normalised}
              />
            ))}
          </Box>

          {/* Show More / Show Less button with Tooltip */}
          {hasExtraTags && (
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Tooltip title={hiddenCriteriaNames || "No additional tags"}>
                <Button size="small" onClick={toggleShowMore}>
                  {showMore ? 'Show Less' : `Show More (${criterions.length - 3} more)`}
                </Button>
              </Tooltip>
            </Box>
          )}

          {/* Release Date */}
          <Typography variant="body2" color="text.secondary" >
            Release Date: {competitorData.release_date_raw || "Unknown"}
          </Typography>

          <Typography variant="body1" color="text.primary" sx={{display: { xs: 'none', sm: 'block' }}}>
            {competitorData.description}
          </Typography>

          {/* Render URLs as clickable chips */}
          <Box sx={{ 
            flexWrap: 'wrap', 
            gap: 2, 
            mt: 2,              
            justifyContent: 'center', // Horizontally center the tags
            alignItems: 'center', // Vertically align the tags
            textAlign: 'center' ,
            display: { xs: 'none', sm: 'flex' }
          }}>
            {competitorData.source_url && (
              <Chip
                icon={<ShoppingCartCheckoutIcon />}
                label={`View ${SOURCE_DESCRIPTION || 'Source'} Page`}
                component="a"
                href={competitorData.source_url}
                clickable
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            {competitorData.website && (
              <Chip
                icon={<HttpIcon />}
                label="Visit Website"
                component="a"
                href={competitorData.website}
                clickable
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default CompetitorDetails;
