import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TabbedPageLayout from '../components/TabbedPageLayout';
import CompetitorDetails from '../components/CompetitorDetails';
import SEO from '../components/SEO';
import FaceOffDataTable from '../components/FaceOffDataTable';
import Login from './Login';
import { CircularProgress, Alert, Typography } from '@mui/material';
import axios from 'axios';
import { useUser } from '../UserContext';

const CompetitorPage = ({ id, showCriterion = true }) => {
  const { user } = useUser();

  const rankingsCells = [
    { id: 'ranking', label: 'Ranking', type: 'text', numeric: true },
    { id: 'tier', label: 'Tier', type: 'tier', numeric: false },
    {
      id: 'leaderboard_name',
      label: 'Leaderboard',
      type: 'link',
      numeric: false,
      properties: { defaultEmoji: '🏆', page_name: 'TierList/id/', link_data: 'leaderboard_id' },
    },
    { id: 'faceoff', label: '', type: 'faceoff', numeric: false, properties: { criterion: 'criterion', competitor: 'corelation_id' } },
    {
      id: 'share',
      label: '',
      type: 'share',
      properties: {
        primaryCompetitorId: 'corelation_id',
        primaryCompetitorName: 'name',
        primaryCompetitorImage: 'large_image_url',
        criterion: 'criterion',
      },
    },
  ];

  const unseededCells = [
    { id: 'ranked_count', label: '# Ranked', type: 'text' },
    {
      id: 'leaderboard_name',
      label: 'Leaderboard',
      type: 'link',
      numeric: false,
      properties: { defaultEmoji: '🏆', page_name: 'Leaderboard/id/', link_data: 'leaderboard_id' },
    },
    { id: 'faceoff', label: '', type: 'faceoff', numeric: false, properties: { criterion: 'criterion', competitor: 'corelation_id' } },
    {
      id: 'share',
      label: '',
      type: 'share',
      properties: {
        primaryCompetitorId: 'corelation_id',
        primaryCompetitorName: 'name',
        primaryCompetitorImage: 'large_image_url',
        criterion: 'criterion',
      },
    },
  ];

  const { corelation_id } = useParams();
  const [competitorData, setCompetitorData] = useState(null);
  const [criterions, setCriterionsData] = useState(null);
  const [images, setImagesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCompetitorData = async (competitorId) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/competitor/${competitorId}`;
      const response = await axios.get(url);
      setCriterionsData(response.data.criterions);
      setCompetitorData(response.data.competitor);
      setImagesData(response.data.images);
      document.title = response.data.competitor.name;
    } catch (err) {
      setError('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const competitorId = id || corelation_id;
    if (competitorId) {
      fetchCompetitorData(competitorId);
    }
  }, [id, corelation_id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!competitorData) {
    return <Typography>No competitor data found.</Typography>;
  }

  const customTopCard = (
    <>
      <SEO
        title={`Is ${competitorData.name} the best Steam Game?`}
        description={`Compare and rank ${competitorData.name} to other similar Steam Games.`}
        image={competitorData.small_image_url}
        url={window.location.href}
      />
      <CompetitorDetails
        competitorData={competitorData}
        {...(showCriterion && { criterions })}
        images={images}
      />
    </>
  );

  const tabs = [
    {
      label: 'Global Rankings',
      title: 'Global Rankings',
      subtitle: 'All users rankings are considered in this leaderboard.',
      content: (
        <FaceOffDataTable
          endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?competitor_id=${corelation_id}&seeded=1`}
          headCells={rankingsCells}
          defaultSortField="ranking"
          defaultSortOrder="desc"
        />
      ),
    },
    {
      label: 'Your Rankings',
      title: 'Your Rankings',
      subtitle: 'Only your rankings are considered in this leaderboard.',
      content: user ? (
        <FaceOffDataTable
          endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?competitor_id=${corelation_id}&seeded=1&profile_id=${user.id}`}
          headCells={rankingsCells}
          defaultSortField="ranking"
          defaultSortOrder="desc"
        />
      ) : (
        <Login />
      ),
    },
    {
      label: 'Unrated Rankings',
      title: 'Unrated Rankings',
      subtitle: 'This competitor has not been ranked enough times to be seeded and added to the leaderboards below.',
      content: (
        <FaceOffDataTable
          endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?competitor_id=${corelation_id}&seeded=0`}
          headCells={unseededCells}
          defaultSortField="ranked_count"
          defaultSortOrder="desc"
        />
      ),
    },
  ];

  return (
    <>
      <SEO title={competitorData.name ? `${competitorData.name} Rankings` : "Competitor Rankings"} description="View the rankings of your competitors and your personal rankings." />
      <TabbedPageLayout customTopCard={customTopCard} tabs={tabs} />
    </>
  );
};

export default CompetitorPage;
