import FaceOffImageList from '../components/FaceOffImageList';
import React from 'react';
import SEO from '../components/SEO';
import TabbedPageLayout from '../components/TabbedPageLayout';

const MyCompetitors = () => {
    const title = `My ${process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors'}`;
    const description = "View your linked competitors.";

    const topCardProps = {
        title: title,
        body: [
            "These are the " + (process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors') + " linked with your profile. You cannot rank any that are not linked to your account, and you cannot manually link them from this site."
        ]
    };

    const tabs = [
        {
            label: 'My Competitors',
            title: title,
            content: (

                <FaceOffImageList dataSource={`${process.env.REACT_APP_SERVER_URL}/GetMyCompetitors`} />

            ),
        },
    ];

    return (
        <>
            <SEO 
                title={title} 
                description={description} 
            />
            <TabbedPageLayout topCardProps={topCardProps} tabs={tabs} />
        </>
    );
};

export default MyCompetitors;
