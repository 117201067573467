import React  from 'react';
import { Container } from '@mui/material';
import FaceOff from '../components/FaceOff';
import { useLocation } from 'react-router-dom';


const FaceOffPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const initPrimaryOpponent = query.get('primary_opponent_id');
  const initCriteriaName = query.get('criterion_name');
  const mode = query.get('mode');
  const shareid = query.get('shareid');

  return (
    <Container>
      <FaceOff initPrimaryOpponent={initPrimaryOpponent} initCriteriaName={initCriteriaName} shareid={shareid} mode={mode}/>
    </Container>
  );
};

export default FaceOffPage;
